<template>
  <div class="table-fields">
    <table>
      <tr>
        <th v-for="th in columns" :key="th.id">
          {{ th.title }}
        </th>
      </tr>
      <tr v-for="(tr, index) in rows" :key="index">
        <td v-for="td in tr" :key="td.id">
          {{ td.value }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TicketFields',
  props: {
    columns: {
      type: Array,
      default: undefined,
    },
    rows: {
      type: Array,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  margin-bottom: 20px;
  border-spacing: 0;
  border-collapse: collapse;
  border-style: hidden;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #ddd;
  th, td {
    padding: 5px;
    border: 1px solid #ddd;
  }
}
</style>
